<template>
  <div>
    <div class="container">
      <div class="row" id="fb-header">
        <div class="col-12 col-lg-6 col-xl-8 center-text">
          {{ $t("facebookPosts.description") }}
        </div>
        <div class="col-12 col-lg-6 col-xl-4" style="text-align: center;">
          <a href="https://www.facebook.com/groups/244756710882712"
            ><img :src="getFbGroupPicUrl()" width="280"
          /></a>
        </div>
      </div>
      <div>
        <div id="desktop-header" class="container d-none d-lg-block">
          <div class="title">
            {{ $t("header.KolSharing") }}
          </div>
        </div>
        <div id="mobile-header" class="d-block d-lg-none">
          <div class="title">
            {{ $t("header.KolSharing") }}
          </div>
        </div>
      </div>
      <div class="year-tab">
        <span
          @click="switchTab('2021')"
          :class="{ selected: this.year === '2021' }"
          >2021</span
        >
        <span>|</span>
        <span
          @click="switchTab('2022')"
          :class="{ selected: this.year === '2022' }"
          >2022</span
        >
        <span>|</span>
        <span
          @click="switchTab('2023')"
          :class="{ selected: this.year === '2023' }"
          >2023</span
        >
        <span>|</span>
        <span
          @click="switchTab('2024')"
          :class="{ selected: this.year === '2024' }"
          >2024</span
        >
      </div>

      <!-- <div id="fb-root"></div>
      <div class="fb-like" data-href="https://www.facebook.com/100044385460984/videos/610325163686109" data-width="" data-layout="button" data-action="like" data-size="large" data-share="true"></div> -->

      <div class="masonry" style="padding: 0 15px">
        <div v-if="loading==false" class="masonry-item" :key="index" v-for="(item, index) in urlList">
          <a :href="item.url"
            ><img
              :src="item.image"
              loading="lazy"
              class="facebook-post-img"
              width="350"
              height="600"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FacebookPosts",
  title: "小小博物館 KOL",
  data() {
    return {
      year:'2024',
      urlList: [],
      loading:false
    };
  },
  methods: {
    switchTab: function(year) {
      this.urlList = [];
      this.loading = true;
      this.year = year;
      this.getUrlList();
    },
    getUrlList: function() {
      let vue = this;

      // TODO: API to get facebook thumbnail and links
      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/post_components/post-get-embed-code.php?year=${this.year}`,
      }).then((response) => {
        for (const key in response.data) {
          const item = response.data[key];
          if (item.status == "enable" && item.imgPath != null) {
            vue.urlList.push({
              image: `${this.$S3_BUCKET_URL}/` + item.imgPath,
              url: item.embed_code,
            });
          }
        }
        this.loading = false;
      });
    },
    goToPage: function(page) {
      this.page = page;
      this.loadFacebook();
      console.log(page);
    },
    getFbGroupPicUrl: function() {
      return require(`../assets/images/facebook-2024-${this.$i18n.locale}.jpg`);
    },
  },
  mounted: function() {
    this.getUrlList();
    this.$forceUpdate();
  },
};
</script>

<style>
.masonry {
  -moz-column-count: 3;
  -moz-column-gap: 3%;
  -moz-column-width: 30%;
  -webkit-column-count: 3;
  -webkit-column-gap: 3%;
  -webkit-column-width: 30%;
  column-count: 3;
  column-gap: 3%;
  column-width: 30%;
  margin: 24px 0;
  margin-right: 15px;
}

.masonry .masonry-item {
  margin-bottom: 12px;
  text-align: center;
}

@media (max-width: 1200px) {
  .masonry {
    column-count: 2;
  }
}

@media (max-width: 996px) {
  .masonry {
    column-count: 1;
  }
}

.fb-post > span {
  width: 350px !important;
}

.fb-post iframe {
  overflow: hidden;
  width: 100% !important;
}

.pagination {
  display: inline-block;
}

.pagination span.link {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
}

.pagination span.link.active {
  font-weight: bold;
}

#fb-header {
  margin-top: 24px;
  margin-bottom: 32px;
}

div.center-text {
  margin: auto;
  width: 50%;
  padding: 0 15%;
  text-align: center;
  font-size: 28px;
}
.facebook-post-img {
  width: 350px;
  height: auto;
}
.year-tab {
  text-align: center;
  margin-top: 20px;
}
.year-tab span {
  font-size: 2rem;
  margin: 0 12px;
  cursor: pointer;
}
.year-tab span.selected {
  color: #ea9852;
  font-weight: bold;
}
@media (max-width: 1200px) {
  div.center-text {
    padding: 0 5%;
  }
}
@media (max-width: 996px) {
  div.center-text {
    margin-bottom: 12px;
  }
  .facebook-post-img {
    width: 100%;
    max-width: 350px;
  }
  .masonry {
    margin-right: 0px;
  }
}
</style>
