<template>
  <div>
    <div id="desktop-header" class="container d-none d-lg-block">
      <div class="title">
        {{ $t("header.activity") }}
      </div>
    </div>
    <div id="mobile-header" class="d-block d-lg-none">
      <div class="title">
        {{ $t("header.activity") }}
      </div>
    </div>
    <div id="html-dom-form-framework" class="container">
      <div id="content">
        <div style="display: flex; justify-content: center">
          <div class="subtitle" style="margin: 26px 0; margin-top: 32px;">
            {{ $t("event.eventEnded") }}
          </div>
        </div>

        <!-- <div class="btn-container" style="margin-top: 24px;">
          <router-link class="item" :to="{ name: 'Methods' }">
            <a
            href="https://www.ohpama.com/wp-content/uploads/master/2023/10/Public-Activity-Details-1.pdf"
            target="_blank"
          >
            <img src="../assets/images/btn-yellow.svg" class="yellow-btn" />
            <div class="centered" style="font-size:24px">
              {{ $t("event.details") }}
            </div>
            </a>
          </router-link>
        </div> -->

        <div id="events">
          <div
            class="event-record"
            v-for="(event, index) in eventList.filter(
              (item) => item.isCoreActivity
            )"
          >
            <div class="section-header" v-if="index == 0">
              <div class="title">
                {{ $t("event.sectionTitle1") }}
              </div>
            </div>
            <div v-else>
              <hr
                class="c-hr"
                style="border-color: #ffffff; margin: 12px 0px"
              />
            </div>
            <div class="subtitle">{{ event.title }}</div>
            <div class="row" style="margin-top: 24px">
              <div class="col-12 col-lg-5" style="text-align: center">
                <img class="img-fluid section-image" :src="event.previewImg" />
              </div>
              <div class="col-12 col-lg-7">
                <div class="row section">
                  <div class="col-6">
                    <div class="section-title">
                      {{ $t("event.dateAndTime") }}
                    </div>
                    <div>
                      <pre>{{ event.dateTime }}</pre>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="section-title">{{ $t("event.venue") }}</div>
                    <div>
                      <pre>{{ event.venue }}</pre>
                    </div>
                  </div>
                  <div
                    class="col-6"
                    v-if="event.capacity != null && event.capacity != '0'"
                  >
                    <div class="section-title">{{ $t("event.quota") }}</div>
                    <div>
                      <pre>{{ event.capacity }}</pre>
                    </div>
                  </div>
                  <div
                    class="col-12"
                    v-if="event.customJson.length > 0"
                    v-for="customField in event.customJson"
                  >
                    <div class="section-title">{{ customField.title }}</div>
                    <div>
                      <pre>{{ customField.content }}</pre>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-title" style="margin-top: 24px">
                    {{ $t("event.description") }}
                  </div>
                  <div>
                    <pre>{{ event.description }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="event-record"
            v-for="(event, index) in eventList.filter(
              (item) => !item.isCoreActivity
            )"
          >
            <div class="section-header" v-if="index == 0">
              <div class="title">
                {{ $t("event.sectionTitle2") }}
              </div>
            </div>
            <div v-else>
              <hr
                class="c-hr"
                style="border-color: #ffffff; margin: 12px 0px"
              />
            </div>
            <div class="subtitle">{{ event.title }}</div>
            <div class="row" style="margin-top: 24px">
              <div class="col-12 col-lg-5" style="text-align: center">
                <img class="img-fluid section-image" :src="event.previewImg" />
              </div>
              <div class="col-12 col-lg-7">
                <div class="row section">
                  <div class="col-6">
                    <div class="section-title">
                      {{ $t("event.dateAndTime") }}
                    </div>
                    <div>
                      <pre>{{ event.dateTime }}</pre>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="section-title">{{ $t("event.venue") }}</div>
                    <div>
                      <pre>{{ event.venue }}</pre>
                    </div>
                  </div>
                  <div
                    class="col-6"
                    v-if="event.capacity != null && event.capacity != '0'"
                  >
                    <div class="section-title">{{ $t("event.quota") }}</div>
                    <div>
                      <pre>{{ event.capacity }}</pre>
                    </div>
                  </div>
                  <div
                    class="col-12"
                    v-if="event.customJson.length > 0"
                    v-for="customField in event.customJson"
                  >
                    <div class="section-title">{{ customField.title }}</div>
                    <div>
                      <pre>{{ customField.content }}</pre>
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-title" style="margin-top: 24px">
                    {{ $t("event.description") }}
                  </div>
                  <div>
                    <pre>{{ event.description }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="remarks">
          <ul class="normal-text">
            <li>{{ $t("eventSessionRegistration.remark1") }}</li>
            <li>{{ $t("eventSessionRegistration.remark2") }}</li>
            <li>{{ $t("eventSessionRegistration.remark3") }}</li>
            <li>{{ $t("eventSessionRegistration.remark4") }}</li>
            <li>{{ $t("eventSessionRegistration.remark5") }}</li>
            <li>{{ $t("eventSessionRegistration.remark6") }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "events",
  title: "小小博物館 KOL",
  data() {
    return {
      eventList: [],
    };
  },
  methods: {
    getEventLists: function() {
      var vue = this;

      // Get current locale
      let locale = "tc";
      if (["sc", "tc", "en"].includes(vue.$i18n.locale))
        locale = vue.$i18n.locale;

      // FIre HTTP Request to CMS Server
      axios({
        method: "get",
        url: `${vue.$CMS_API_URL}/events_components/launched_events.php?lang=${locale}`,
      })
        .then((response) => {
          console.log(response.data);

          vue.eventList = response.data
            .map((item) => {
              let category = "-";
              const categoryCode = item.category;
              let isCoreActivity = false;
              switch (categoryCode) {
                case "core-1":
                  category = vue.$t("event.core1");
                  isCoreActivity = true;
                  break;
                case "core-2":
                  category = vue.$t("event.core2");
                  isCoreActivity = true;
                  break;
                case "core-3":
                  category = vue.$t("event.core3");
                  isCoreActivity = true;
                  break;
                case "elective-1":
                  category = vue.$t("event.elective1");
                  break;
                case "elective-2":
                  category = vue.$t("event.elective2");
                  break;
                case "elective-3":
                  category = vue.$t("event.elective3");
                  break;
                case "elective-4":
                  category = vue.$t("event.elective4");
                  break;
              }

              return {
                eventId: item.event_id,
                eventRefId: item.event_ref_id,
                title: item.title,
                category: category,
                previewImg: `${this.$S3_BUCKET_URL}/${item.preview_img}`,
                capacity: item.capacity,
                venue: item.venue,
                dateTime: item.date_time,
                description: item.description,
                customJson: JSON.parse(item.custom_json),
                isCoreActivity,
              };
            })
            .sort((a, b) => b.isCoreActivity - a.isCoreActivity);
        })
        .catch(function(error) {
          console.log("erros : ", error);
        });
    },
  },
  mounted: function() {
    this.getEventLists();
  },
  watch: {
    "$i18n.locale": function() {
      this.getEventLists();
    },
  },
};
</script>

<style scoped>
#events {
  margin-top: 42px;
}
.section .section-title {
  font-weight: bold;
  margin-top: 12px;
  word-wrap: break-word;
}
.section {
  font-size: 17px;
}
.section-image {
  max-width: 300px;
}
.event-record {
  margin-bottom: 36px;
}
pre {
  border: 0;
  font-size: 100%;
  background-color: transparent;
  color: inherit;
  white-space: pre-wrap;
  font-family: "Noto Sans CJK TC", Helvetica, Arial, sans-serif;
}
#remarks {
  margin: 82px 0 62px 0;
}
.yellow-btn.method-btn {
  width: 230px;
}
.method-btn-text {
  font-size: 18px;
}
@media (min-width: 360px) {
  .yellow-btn.method-btn {
    width: 320px;
  }
  .method-btn-text {
    font-size: 25px;
  }
}
.section-header {
  text-align: center;
  margin-bottom: 48px;
}
.section-header .title {
  padding: 12px;
  background-color: #ea9852;
}
</style>
